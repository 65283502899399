import { FileDownload } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import downloadImage from "../../assets/images/downloadingfiles.png";
import { sidebarClose, sidebarOpen } from "../../store/sidebar/sidebarSlice";
import {
  previewTransfer,
  startDownload,
} from "../../store/transfer/transferSlice";
import { printSize } from "../../utils";
import LogoLoading from "../LogoLoading";

const Download = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, token } = useParams();
  const transfer = useSelector((state) => state.transfer?.item);
  const info = useSelector((state) => state.info);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!id) {
      transfer?.id !== id && dispatch(previewTransfer({ id, token }));
    }
  }, [id, token, transfer, dispatch]);

  const navigateTo = () => {
    let to;
    if (location.pathname.split("/").length === 4) {
      dispatch(sidebarOpen());
      to = location.pathname + "/preview";
    } else {
      dispatch(sidebarClose());
      to = info.navigate;
    }
    setTimeout(() => {
      navigate(to);
    }, 500);
  };

  if (info.loading?.previewTransfer) {
    return <LogoLoading />;
  }

  return (
    <div className="text-center">
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          width: "100%",
          height: "300px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={downloadImage} alt="" style={{ width: 250 }} />
      </Box>
      <div className="m-auto" style={{ width: 300 }}>
        <p className="text-xl text-left">
          {transfer?.title?.length > 20
            ? transfer?.title?.slice(0, 20) + "..."
            : transfer?.title}
        </p>
        <p className="text-sm text-left">
          {transfer?.expire?.status
            ? transfer?.expire?.reason
            : transfer?.message}
        </p>
      </div>
      <hr />
      <div
        className="m-auto flex justify-between items-center"
        style={{ width: 300, height: 100 }}
      >
        <div>
          <p className="font-light">
            {transfer?.files?.length === 1
              ? t("1 file")
              : t("{{count}} files", {
                count: transfer?.files?.length,
              })}
          </p>
          <div className="text-gray-400 font-thin text-left">
            <small className="pr-1">{printSize(transfer?.size)}</small>
          </div>
        </div>
        <button onClick={navigateTo} className="logo-blue" type="button">
          {t("Preview")}
        </button>
      </div>
      {transfer?.expire?.status === false && (
        <>
          <hr />
          <Box className="p-6 flex justify-center">
            <Button
              type="button"
              variant="contained"
              className="bg-primary rounded-full px-6 normal-case"
              onClick={() => {
                dispatch(
                  startDownload({
                    id,
                    type: "entire",
                    token,
                  })
                );
              }}
              startIcon={<FileDownload />}
            >
              {t("Download")}
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};

export default Download;
