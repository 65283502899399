import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { useEffect, useState } from 'react';
import axios from "../../configs/axios";
import ReCAPTCHA from "react-google-recaptcha";


const FormModal = ({ open = false, handleClose = () => { } }) => {
  const [sent, setSent] = useState(false);
  const [content, setContent] = useState(null);
  const [captchavalid, setCaptchaValid] = useState(false);

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  async function getBetaContent() {
    const response = await axios.get(process.env.REACT_APP_CMS_API_URL + '/pages/show/?type=contact')
    setContent(response?.data)
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    const response = await axios.post(process.env.REACT_APP_CMS_API_URL + "/contacts/form", data);
    if (response.data.status === 'success') {
      setSent(true)
    }

  }
  useEffect(() => {
    if (open) {
      setSent(false)
      getBetaContent()
    }
  }, [open])

  useEffect(() => {
    if (sent) {
      handleClose();
      setOpenSuccessModal(true);
    }
    //eslint-disable-next-line
  }, [sent])
  return (
    <div >

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='rounded-3xl w-[100%] max-h-[90vh] overflow-scroll md:w-[50%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-white p-4'>
          <div class="max-w-md mx-auto mb-8 mt-4">
            <h2>{content?.title}</h2>
            {/* <span dangerouslySetInnerHTML={{ __html: content?.description }} /> */}
            <span>
              Bu sürümde, dosya transfer hızı ve kullanıcı deneyimini iyileştirmeye odaklandık. Dosyalarınızı hızlıca yükleyip paylaşabilir, büyük boyutlu transferlerinizi kesintisiz gerçekleştirebilirsiniz. Beta aşamasında olduğumuz için bazı özellikler tam olarak optimize edilmemiş olabilir; bu süreçte sabrınız bizim için çok değerli.
              Bir hata ile karşılaşırsanız, sayfanın altındaki Destek formunu doldurarak yardım alabilirsiniz. Geri bildirimleriniz, platformumuzu daha iyi hale getirmemize büyük katkı sağlayacaktır.
            </span>
          </div>


          <form onSubmit={handleSubmit} class="max-w-md mx-auto">
            <h2 class="mb-5 font-semibold text-gray-900 dark:text-white">Bize Geri Dönüşte Bulunun</h2>


            <div class="relative z-0 w-full mb-5 group">
              <h3 class="mb-2 font-semibold text-gray-900 dark:text-white">Bildirim Türü</h3>
              <div class="flex items-center mb-4">
                <input id="default-radio-1" type="radio" value="Sorun Bildir" name="subject" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input>
                <label for="default-radio-1" class="ms-2 text-sm font-medium text-black dark:text-black ml-2">Sorun Bildir</label>
              </div>
              <div class="flex items-center mb-4">
                <input checked id="default-radio-2" type="radio" value="Tavsiye Bildir" name="subject" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input>
                <label for="default-radio-2" class="ms-2 text-sm font-medium text-black dark:text-black ml-2">Tavsiye Bildir</label>
              </div>
              <div class="flex items-center">
                <input checked id="default-radio-2" type="radio" value="Diğer bildirimler" name="subject" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input>
                <label for="default-radio-2" class="ms-2 text-sm font-medium text-black dark:text-black ml-2">Diğer bildirimler</label>
              </div>
            </div>
            <div class="relative z-0 w-full mb-5 group">
              <input type="email" name="email" id="email" class="block py-2.5 px-0 w-full text-sm dark:bg-gray-700 text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
              <label for="email" class="peer-focus:font-medium absolute text-sm text-black dark:text-black duration-300 transform -translate-y-9 scale-75 top-5 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9">E-posta</label>
            </div>

            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 w-full mb-5 group">
                <input type="text" name="firstname" id="firstname" class="block py-2.5 px-0 w-full text-sm dark:bg-gray-700 text-black bg-transparent border-0 border-b-2 border-black appearance-none dark:text-white dark:border-black dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <label for="firstname" class="peer-focus:font-medium absolute text-sm text-black dark:text-black duration-300 transform -translate-y-9 scale-75 top-5 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9">İsim</label>
              </div>
              <div class="relative z-0 w-full mb-5 group">
                <input type="text" name="lastname" id="lastname" class="block py-2.5 px-0 w-full text-sm dark:bg-gray-700 text-black bg-transparent border-0 border-b-2 border-black appearance-none dark:text-white dark:border-black dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <label for="lastname" class="peer-focus:font-medium absolute text-sm text-black dark:text-black duration-300 transform -translate-y-9 scale-75 top-5 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9">Soyisim</label>
              </div>
            </div>

            <div class="relative z-0 w-full mb-5 group">
              <textarea name="message" id="message" rows="4" class="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-black focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Mesajınız"></textarea>

            </div>
            <div className='flex my-5 justify-end'>
              <ReCAPTCHA
                sitekey="6LfVoW8qAAAAAFIqQPxcxCOkaCYiiv5jzLskYtdx"
                onChange={(el) => {
                  if (el) {
                    setCaptchaValid(true)

                  }
                }}
              />
            </div>
            <div class="flex justify-between w-full">
              <button onClick={handleClose} class={"bg-gray-700 disabled:bg-gray-500 text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[35%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 rounded-full"}>Kapat</button>

              <button disabled={!sent && !captchavalid} type="submit" class={"bg-blue-700 disabled:bg-gray-500 text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[35%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 rounded-full"}>Gönder</button>
            </div>
          </form>

        </Box>
      </Modal>

      <Modal
        open={openSuccessModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='rounded-3xl w-[100%] max-h-[90vh] overflow-scroll md:w-[30%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-white p-4'>
          <div class="max-w-md mx-auto mb-8 mt-4">
            <h2 className='text-center'>Başarılı!</h2>
            <h3 className='text-center'>
              Beta versiyonumuzu deneyimlediğiniz için teşekkür ederiz!
            </h3>
            <div class="flex justify-center w-full">
              <button onClick={() => { setOpenSuccessModal(false) }} class={"mt-6 bg-gray-700 disabled:bg-gray-500 text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[35%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 rounded-full"}>Kapat</button>
            </div>
          </div>


        </Box>
      </Modal>
    </div>
  );
};

export default FormModal;
