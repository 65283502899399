import { Email, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";


import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import InfoMessage from "../../components/InfoMessage";
import { login } from "../../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { useEffect, useRef, useState } from "react";
import { resetInfo, setInfo } from "../../store/info/infoSlice";

const Login = () => {
  const formRef = useRef(null);
  const submitRef = useRef(null);
  const info = useSelector((state) => state.info);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const validation = yup.object({
    email: yup
      .string()
      .email(t("{{field}} Email is not a valid email", { field: "" }))
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t("{{field}} Email is not a valid email", { field: "" })
      )
      .required(t("{{field}} Email is required", { field: "" })),

    password: yup
      .string()
      .required(t("{{field}} is required", { field: t("Password") })),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode:'onChange',
    defaultValues: { email: "", password: "", remember_me: false },
    resolver: yupResolver(validation),
  });

  const onSubmit = (data) => {
    dispatch(resetInfo());
    dispatch(login({ ...data, navigate }));
  };
  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      event.preventDefault();
      const form = formRef.current;
      const inputs = Array.from(form.querySelectorAll('input'));
      const focusedIndex = inputs.indexOf(document.activeElement);
      if (focusedIndex > -1 && focusedIndex < inputs.length - 1) {
        // Eğer odaklanılan input son input değilse, bir sonrakine odaklan.
        inputs[focusedIndex + 1].focus();
      } else {
        // Eğer odaklanılan input son input ise, formu gönder.
        submitRef.current.click()
      }
    }
  };
  useEffect(() => {
    dispatch(resetInfo());
    const queryParameters = new URLSearchParams(window.location.search)
    const message = queryParameters.get("m")
    if(message === 'sreset'){
      dispatch(
        setInfo({
          message:
            "A password reset link will be sent to your email address. Please check your email and follow the instructions.",
        })
      );
    }
  }, [dispatch]);

  return (
    <Layout
      header={<Header whiteBg={true} />}
      className={"fullscreen auth"}
      noUploader
    >
      <div className="flex" style={{ height: "calc(100vh - 100px)" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          style={{ height: 500, margin: "auto" }}
        >
          <Grid md={5} xs={12} item>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={0} variant="fullWidth">
                <Tab
                  className="text-gray-600 border-gray-600 normal-case"
                  label={t("Login")}
                />
                <Tab
                  onClick={() => {
                    navigate("/register");
                  }}
                  className="text-gray-600 border-gray-600 capitalize"
                  label={t("Register")}
                />
              </Tabs>
            </Box>

            <div style={{ padding: 24 }}>
              <form ref={formRef} onKeyDown={handleKeyDown} onSubmit={handleSubmit(onSubmit)}>
                <Box className="p-3">
                  <div className="info-message-container">
                    <InfoMessage />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="email"
                      control={control}
                      
                      render={({ field }) => (
                        <TextField
                          error={errors.email && true}
                          size="small"
                          fullWidth
                          placeholder={t("Email")}
                          helperText={errors.email && errors.email.message}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email />
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          error={errors.password && true}
                          size="small"
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          placeholder={t("Password")}
                          helperText={
                            errors.password && errors.password.message
                          }
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{ padding: 11 }}
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  {/* <div className="mb-4 hidden">
                    <Controller
                      name="remember_me"
                      control={control}
                      render={({ field }) => (
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox {...field} />}
                            label={t("Remember Me!")}
                          />
                        </FormGroup>
                      )}
                    />
                  </div> */}
                  <div className="mb-4">
                    <Button
                      ref={submitRef}
                      type="submit"
                      variant="contained"
                      className="rounded-full w-full normal-case"
                      disabled={info.loading?.login}
                      startIcon={
                        info.loading?.login && (
                          <CircularProgress size={"1rem"} />
                        )
                      }
                    >
                      {t("Login")}
                    </Button>
                  </div>
                  <div className="mb-4 text-center">
                    <Link
                      to="/forget-password"
                      className="underline logo-blue cursor-pointer"
                    >
                      {t("Forgot your password?")}
                    </Link>
                  </div>
                </Box>
              </form>
            </div>
          </Grid>
          <Grid md={7} xs={12} item>
            <Box className="auth-background h-full hidden lg:block"></Box>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};
export default Login;
