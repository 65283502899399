import { Search } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HorizontalTabs from "../../components/Tabs/HorizontalTabs";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { getTransfers, setParams } from "../../store/transfer/transferSlice";
import List from "./List";

const Transfer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentDirection, setCurrentDirection] = useState("sent");
  const params = useSelector(
    (state) => state.transfer[currentDirection].params
  );

  const localSetParams = (param, value) => {
    let tempParams = { ...params, direction: currentDirection };
    tempParams[param] = value;
    dispatch(setParams(tempParams));
    dispatch(getTransfers(currentDirection));
  };

  useEffect(() => {
    localSetParams("search", '');
    document.querySelector('#search').value = ''
    //eslint-disable-next-line
  }, [currentDirection])

  return (
    <Layout
      header={<Header />}
      sidebarContent
      className={"fullscreen home-image"}
      bgImg={true}
    >
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className="pb-5"
        >
          <Grid item lg={7} md={6}>
            <h3 className="text-4xl">{t("Transfers")}</h3>
          </Grid>
          <Grid item lg={5} md={6}>
            <TextField
              id="search"
              size="small"
              onChange={(e) =>
                setTimeout(() => {
                  localSetParams("search", e.target.value);
                }, 1000)
              }
              placeholder={t("Search for title or email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>

        <HorizontalTabs
          tabs={[
            {
              label: t("Sent"),
              component: (
                <List
                  direction="sent"
                  setCurrentDirection={setCurrentDirection}
                />
              ),
            },
            {
              label: t("Received"),
              component: (
                <List
                  direction="received"
                  setCurrentDirection={setCurrentDirection}
                />
              ),
            },
          ]}
        />
      </>
    </Layout>
  );
};
export default Transfer;
